import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns'; // Import format function from date-fns
import back from '../../assest/left-arrow.png';
import { FiAlertCircle } from "react-icons/fi";

function SelectDate({setFormData, laststep, setLastStep, changeStep }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [arrivalErrorMessage, setArrivalErrorMessage] = useState('');
  const [departureErrorMessage, setDepartureErrorMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      startDate: format(date, 'dd/MM/yyyy'),
       // Format the date before saving
    }));
    if (endDate && date > endDate) {
      setEndDate(null); // Reset the end date if the start date is after it
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      alert('End date cannot be before start date');
    } else {
      setEndDate(date);
      setFormData((prevState) => ({
        ...prevState, // Spread the previous state to retain other properties
        endDate: format(date, 'dd/MM/yyyy'), // Format the date before saving
      }));
    }
  };

  const handleSubmit = () => {
    if (startDate == null && endDate == null) {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setArrivalErrorMessage("This field is required.");
      setDepartureErrorMessage("This field is required.");
    } else if (startDate == null) {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setArrivalErrorMessage("This field is required.");
      setDepartureErrorMessage("");
    } else if (endDate == null) {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setDepartureErrorMessage("This field is required.");
      setArrivalErrorMessage(" ");
    } else {
      setErrorMessage('');
      setDepartureErrorMessage("");
      setArrivalErrorMessage(" ");
      setLastStep(5);
      changeStep(8);
    }
  };

  // Custom input component to include calendar icon
  const CustomInput = ({ value, onClick, placeholder }) => (
    <button className="date-btn" onClick={onClick}>
      {value || placeholder}
    </button>
  );

  const clickPrev = (step) => {
    setLastStep(step - 1);
    changeStep(step);
  };

  return (
    <div className='container'>
      {errorMessage ? (
        <div className="error-message-top">
          <FiAlertCircle className='fi-icon' />&nbsp;
          {errorMessage}
        </div>
      ) : null}
      <h1 className="heading">Select your dates</h1>

      <div className="date-div">
        <div className="arrival-date">
          <p className="date-heading">Arrival Date</p>
          <DatePicker
            selected={startDate || null}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()} // Disable past dates
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            customInput={<CustomInput />}
          />
          {arrivalErrorMessage && (
            <div className="error-message-bottom">
              {arrivalErrorMessage}
            </div>
          )}
        </div>

        <div className="departure-date">
          <p className="date-heading">Departure Date</p>
          <DatePicker
            selected={endDate || null}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate || new Date()} // Disable past dates and ensure end date is after start date
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            customInput={<CustomInput />}
          />
          {departureErrorMessage && (
            <div className="error-message-bottom">
              {departureErrorMessage}
            </div>
          )}
        </div>
      </div>

      <button onClick={handleSubmit} className="org-btn">Next</button>
      <Link to="#" onClick={() => clickPrev(laststep)} className='backward'>
        <img src={back} alt="logo" className='back-btn' />
      </Link>
      <div style={{ padding: '20px' }}>
        <span className='pro-heading'>Progress</span>
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `50%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default SelectDate;
