// formReducer.js
const initialState = {
    
    firstName: '',
    lastName: '',
    email: '',
    country: null,
    phoneNumber: '',
    isChecked: false
  };
  
  const formReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_FORM':
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };
  const initialValue ='Null';
  export const selectYesNo = (state=initialValue, action) =>{
    console.log(action);
    switch (action.type){
      case 'yes': return state='yes';
      case 'no': return state ='no';
      default: return state;
    }
  }

  // not completed
  const initialPlace = 'Null';
  export const selectedPlace =(state =initialPlace, action)=>{
    switch(action.type){
      case 'UPDATE_PLACE':
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
        
    }

  }
  const initialbudget ='Null';
  export const selectBudget =(state =initialbudget, action) =>{
    console.log(state);
    return state;
  
  }



  
  export default formReducer;
  