import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
// import "./HowLong.css";
import back from '../../assest/left-arrow.png';



const HowLong = ({data, setFormData, laststep,  setLastStep, changeStep}) => {
  const [activeButton, setActiveButton] = useState(null);
 

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      duration: buttonLabel, // Update the name field
    }));
    setLastStep(7);
    changeStep(8);
  };
  const clickPrev = (step) => {
    setLastStep(step-1);
    changeStep(step);
  }

  return (
    <div className='container'>
      <h1 className='heading'>How long do you want to travel?</h1>
      <div className='div-hbut'>
        <Link 
          className={`hbut1 ${data.duration === 'LESS THEN ONE WEEK' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('LESS THEN ONE WEEK')}
        >
          LESS THEN ONE WEEK
        </Link>
        <Link 
          className={`hbut1 ${data.duration === 'ONE WEEK' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('ONE WEEK')}
        >
          ONE WEEK
        </Link>
        <Link 
          className={`hbut1 ${data.duration === '10 DAYS' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('10 DAYS')}
        >
          10 DAYS
        </Link>
        <Link 
          className={`hbut1 ${data.duration === '2 WEEKS' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('2 WEEKS')}
        >
          2 WEEKS
        </Link>
        <Link 
          className={`hbut1 ${data.duration === '2 WEEKS+' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('2 WEEKS+')}
        >
          2 WEEKS +
        </Link>
      </div>
      <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
      <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `60%` }}
              ></div>
            </div>
          </div>
    </div>
  );
}

export default HowLong;
