import React, { useState, useEffect } from 'react';
// import './Main.css';
import { Link } from 'react-router-dom'; 

const Main = ({data, setFormData, changeStep}) => {
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    // This will run only once when the component is first mounted
    console.log(activeButton);
  }, [activeButton]);

  const clickNext = (buttonLabel, step) => {

    setActiveButton(buttonLabel);
    
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      main: buttonLabel, // Update the name field
    }));

    changeStep(step);
  }

    return (
      <div className="main-container">
        <div className="container">
          <h1 className="heading">
            Help us plan your dream safari by providing us with a few details (this shouldn’t take more than a minute or two to complete).
          </h1>
          <h4 className="sub-heading">
            Do you know where you want to travel?
          </h4>
          <div className="div-hbut">
            <Link 
              to="#" 
              className={`hbut1 ${data.main === 'yes' ? 'active' : ''}`}
              onClick={() => clickNext('yes', 2)}
            >Yes</Link>
            <Link 
              to="#" 
              className={`hbut1 ${data.main === 'no' ? 'active' : ''}`}
              onClick={() => clickNext('no', 3)}
            >No</Link>
          </div>
          
        </div>
        <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `0%` }}
              ></div>
            </div>
          </div>


        </div>
      );
}

export default Main
