
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import back from '../../assest/left-arrow.png';
// import '../SelectPlace/SelectPlace.css';
import { FiAlertCircle } from "react-icons/fi";

const Select_Place = ({data, setFormData, laststep,  setLastStep, changeStep}) => {
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [bottomErrorMessage, setBottomErrorMessage] = useState('');
    const buttonLabels = ['KENYA', 'TANZANIA', 'RWANDA', 'UGANDA', 'ZANZIBAR'];

    const handleButtonClick = (label) => {
        setSelectedButtons((prevSelected) =>
            prevSelected.includes(label)
                ? prevSelected.filter((item) => item !== label)
                : [...prevSelected, label]
        );

        setFormData((prevState) => ({
            ...prevState, // Spread the previous state to retain other properties
            place: [...prevState.place, label], 
            // Update the name field
        }));
    };

    const handleNextClick = (step) => {
        if (selectedButtons.length === 0) {
            setErrorMessage('There was a problem with your submission. Please review the fields below.');
            setBottomErrorMessage('This field is required.');
        } else {
            setErrorMessage('');
            setBottomErrorMessage('');         
            setLastStep(2);
            changeStep(step);
        }
    };

    const clickPrev = (step) => {
        setLastStep(1);
        changeStep(step);
    }

    return (
        <div className="main-container">
            {errorMessage && (
                <div className="error-message-top">
                    <FiAlertCircle className='fi-icon' /> &nbsp; 
                    {errorMessage}
                </div>
            )}
        <div className="container">
            
            <h1 className="heading">Where would you like to travel?</h1>
            <div className="div-hbut">
                {buttonLabels.map((label) => (
                    <button
                        className="hbut1"
                        key={label}
                        onClick={() => handleButtonClick(label)}
                        style={{
                            backgroundColor: selectedButtons.includes(label) ? 'black' : 'transparent',
                            color: selectedButtons.includes(label) ? 'white' : 'black',
                        }}
                    >
                        {label}
                    </button>
                ))}
            </div>

            {bottomErrorMessage && (
                <div className="error-message-bottom">
                    {bottomErrorMessage}
                </div>
            )}

            <button
                className="org-btn"
                onClick={() => handleNextClick(3)}
            >
                NEXT
            </button>
            <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>

            
        </div>
        <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `15%` }}
              ></div>
            </div>
          </div>
        </div>
    );
};

export default Select_Place;