import React from 'react'
import  { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import back from '../../assest/left-arrow.png';
// import './Message.css'


const Message = ({ setFormData, laststep,  setLastStep, changeStep}) => {
    const [showmessage, setShowMessage] = useState('');

    const handleChange = (event) => {
        setShowMessage(event.target.value);
        
    };
    const navigate = useNavigate(); 
    const handleSubmit = () => {
        console.log('User Input:', showmessage);
        setFormData((prevState) => ({
          ...prevState, // Spread the previous state to retain other properties
          message: showmessage, // Update the name field
        }));
        setLastStep(10);
        changeStep(11);
        // Handle form submission or other actions here
    };
    const clickPrev = (step) => {
        setLastStep(step-1);
        changeStep(step);
      }
    return (
        <div className="container message-h">
            <h3 className='sub-heading'>
                What do you want to see and do on your safari? For example, tell us your must-haves, destination ideas, or if it's a special occasion. The more you tell us, the better we can assist.
            </h3>
            <textarea
                id="user-input"
                value={showmessage}
                onChange={handleChange}
            /><br/>
            <Link to="/form" onClick={handleSubmit} className='org-btn'>Next</Link>
            <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
            <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `80%` }}
              ></div>
            </div>
          </div>
        </div>
    );
}

export default Message
