// import "../HowLong/HowLong.css";
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import back from '../../assest/left-arrow.png';
import { useDispatch } from 'react-redux';
import { updateBudget } from '../../Store/Action/formActions';

function Budget({data, setFormData, laststep,  setLastStep, changeStep}) {
  const [activeButton, setActiveButton] = useState(null);


  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      budget: buttonLabel, // Update the name field
    }));

    setLastStep(3);
    changeStep(4);
  };

  const clickPrev = (step) => {
    setLastStep(step-1);
    changeStep(step);
  }
  

  return (
    <div className="container">
        <h1 className='heading'>What is your budget per person?</h1>
        <div className='div-hbut'>
          <Link 
            className={`hbut1 ${data.budget=== 'LESS THAN US$5,000' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('LESS THAN US$5,000')}
          >
            LESS THAN US$5,000
          </Link>
          <Link 
            className={`hbut1 ${data.budget === 'US$5,000 - US$8,000' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('US$5,000 - US$8,000')}
          >
            US$5,000 - US$8,000
          </Link>
          <Link 
            className={`hbut1 ${data.budget === 'US$8,000 - US$10,000' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('US$8,000 - US$10,000')}
          >
            US$8,000 - US$10,000
          </Link>
          <Link 
            className={`hbut1 ${data.budget=== 'US$10,000 +' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('US$10,000 +')}
          >
           US$10,000 +
          </Link>
          <Link 
            className={`hbut1 ${data.budget === 'NOT SURE' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('NOT SURE')}
          >
            NOT SURE
          </Link>
        </div>
        <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
        <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `20%` }}
              ></div>
            </div>
          </div>

        {/* <button className='p-btn place-btn'>Less than US$5,000</button>
        <button className='p-btn place-btn'>US$5,000 - US$8,000</button>
        <button className='p-btn place-btn'>US$8,000 - US$10,000</button>
        <button className='p-btn place-btn'>US$10,000 +</button>
        <button className='p-btn place-btn'>NOT SURE</button> */}
    </div>
  )
}

export default Budget