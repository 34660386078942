import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import back from '../../assest/left-arrow.png';
import { FiAlertCircle } from "react-icons/fi";

function SafariTravel({ data, setFormData, laststep, setLastStep, changeStep }) {
  const [safaris, setSafaris] = useState('');
  const [transfers, setTransfers] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [arrivalErrorMessage, setArrivalErrorMessage] = useState('');
  const [departureErrorMessage, setDepartureErrorMessage] = useState('');
 
  const handleSafariChange = (event) => {
    setSafaris(event.target.value);
    setFormData((prevState) => ({
        ...prevState, // Spread the previous state to retain other properties
        safari: event.target.value, // Update the name field
      }));
  
  };

  const handleTransferChange = (event) => {
    setTransfers(event.target.value);
    setFormData((prevState) => ({
        ...prevState, // Spread the previous state to retain other properties
        Travel: event.target.value, // Update the name field
      }));
  };

  const handleSubmit = () => {
    if (safaris === '' && transfers === '') {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setArrivalErrorMessage("This field is required.");
      setDepartureErrorMessage("This field is required.");
    } else if (safaris === '') {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setArrivalErrorMessage("This field is required.");
      setDepartureErrorMessage("");
    } else if (transfers === '') {
      setErrorMessage('There was a problem with your submission. Please review the fields below.');
      setDepartureErrorMessage("This field is required.");
      setArrivalErrorMessage(" ");
    } else {
      setErrorMessage('');
      setDepartureErrorMessage("");
      setArrivalErrorMessage(" ");
      setLastStep(9);
      changeStep(10);

      // Submit the selected numbers to your backend or handle it as needed
    }
  };

  const clickPrev = (step) => {
    setLastStep(step - 1);
    changeStep(step);
  };

  return (
    <div className='container'>
      {errorMessage ? (
        <div className="error-message-top">
          <FiAlertCircle className='fi-icon' />&nbsp;
          {errorMessage}
        </div>
      ) : null}
      <h1 className="heading">Select No. of Safari's & Transfers</h1>

      <div className="date-div">
        <div className="arrival-date">
          <p className="date-heading">Safari's</p>
          <select className='hbut1 selet-btn' value={safaris} onChange={handleSafariChange}>
            <option disabled value="">Select number of safaris</option>
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          {arrivalErrorMessage && (
            <div className="error-message-bottom">
              {arrivalErrorMessage}
            </div>
          )}
        </div>
        <div className="departure-date">
          <p className="date-heading">Transfers</p>
          <select className='hbut1 selet-btn' value={transfers} onChange={handleTransferChange}>
            <option disabled value="">Select number of transfers</option>
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          {departureErrorMessage && (
            <div className="error-message-bottom">
              {departureErrorMessage}
            </div>
          )}
        </div>
      </div>

      <button onClick={handleSubmit} className="org-btn">Next</button>
      <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
      <div style={{ padding: '20px' }}>
        <span className='pro-heading'>Progress</span>
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: '50%' }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default SafariTravel;