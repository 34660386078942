import {combineReducers} from 'redux'
import formReducer, { selectYesNo, selectBudget, selectedPlace} from './formReducer';


const rootReducer = combineReducers({
  form: formReducer,
  main: selectYesNo,
  place: selectedPlace,
  budget: selectBudget
});

export default rootReducer;